class Image {
    id;
    constructor(id, url, name, date) {
        this.id = id || '';
        this.url = url || '';
        this.name = name || '';
        this.date = date || '';
    }
}
export default {
    toFirestore: function(image) {
        return {
            id: image.id,
            url: image.url,
            name: image.name,
            date: image.date,
        }
    },
    fromFirestore: function(snapshot, options) {
        const data = snapshot.data(options);
        return new Image(data.id, data.url, data.name, data.date)
    }
}