import BaseModel from './BaseModel';
import firebase from 'firebase/app';
import 'firebase/storage';
import ImageDataConverter from '@/FirestoreDataConverters/Image.js';


export default class Image extends BaseModel {
  constructor(options) {
      super(options);
  }
      // agrega la imagen principal y actividades
  addImg(id, obj) {
    var storage = firebase.storage();
    let refstorage = storage.ref('tours/' + id).child('principal').child(obj.name).put(obj);
    return refstorage;
  }
  addImgA(id, obj) {
    var storage = firebase.storage();
    let refstor = storage.ref('tours/' + id).child(obj.name).put(obj);
    // console.log(refstor);
    return refstor;
  }
  // extrae la url de la foto
  downImg(id, name) {
    var storaged = firebase.storage();
    let refdownd = storaged.ref('tours/' + id + '/principal/' + name).getDownloadURL();
    return refdownd;
  }
  downImgA(id, name) {
    var storaged = firebase.storage();
    let refdownd = storaged.ref('tours/' + id + '/' + name).getDownloadURL();
    return refdownd;
  }

  addImgDesc(id, img, name, date) {
    return this.db.collection('tours').doc(id + '/' + "image" + '/' + 'principal').set({
      url: img,
      name: name,
      date: date,
    })
  }
  addImgDescAct(id, img, name, date) {
    return this.db.collection('tours/' + id + '/imageActividades').add({
      url: img,
      name: name,
      date: date,
    })
  }
  findImage(id) {
    return this.db.collection('tours').doc(id).collection('image').doc('principal').withConverter(ImageDataConverter);
  }
  foundName(id, name) {
    return this.db.collection('tours').doc(id).collection("imageActividades").where("name", "==", name).withConverter(ImageDataConverter);
  }
  findImageAct(id) {
    return this.db.collection('tours').doc(id).collection('imageActividades').withConverter(ImageDataConverter);
  }
  deleteImg(id) {
    return this.db.collection('tours').doc(id).collection('image').doc('principal').delete();
  }
  deleteImgAct(id, idimg) {
    return this.db.collection('tours').doc(id).collection('imageActividades').doc(idimg).delete();
  }
  deleteStorageP(id, name) {
    var storage = firebase.storage();
    let refstorage = storage.ref('tours/' + id + '/principal/').child(name).delete();
    return refstorage;
  }
  deleteStorage(id, name) {
    var storage = firebase.storage();
    let refstorage = storage.ref('tours/' + id + '/').child(name).delete();
    return refstorage;
  }

}