<template>
  <div>
    <portal to="title-page">
      <h1 v-if="tour">{{tour.name}}</h1>
    </portal>
    <tour-tabs v-if="tour" tab="images" :tour="tour">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <div>
                <p>Imagen representativa</p>
              </div>
              <div>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-file-input
                      chips
                      v-model="imgInicial"
                      color="primary"
                      show-size
                      dense
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-btn
                      class="subir ma-0 pa-3 white--text"
                      :disabled="imgInicial==null || imgInicial==''"
                      @click="addImg()"
                      color="primary"
                    >Subir</v-btn>

                    <v-btn
                      v-on:click="deleteImg(imagenP.name)"
                      class="eliminar white--text ma-0 pa-3"
                      :disabled="imagenP == null || imagenP ==''"
                      color="red"
                    >Borrar</v-btn>
                  </v-col>
                </v-row>
              </div>
              <div v-if="imagenP != null">
                <v-card class="d-inline-block">
                  <v-container>
                    <v-row>
                      <v-col cols>
                        <v-img height="30vh" width="20vw" :src="imagenP.url"></v-img>
                        <!-- <v-card-actions>
                        <v-spacer></v-spacer>
                          <v-icon color="red" @click="deleteImg(imagenP.name)">mdi-delete</v-icon>
                        </v-card-actions>-->
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <div>
                <p>Imagenes de actividades</p>
              </div>
              <div>
              </div>
              <div align="center" justify="center">
                <v-data-table
                  v-if="imagenA != null"
                  v-model="selectedA"
                  :headers="tableHeadersA"
                  :items="imagenA"
                  item-key="id"
                  fixed-header
                  class="elevation-1"
                >
                  <template v-slot:top>
                    <div class="table-top-bar">
                      <div class="d-flex">
                        <div class="file-input-width">
                          <v-file-input
                            chips
                            v-model="imgActividades"
                            color="#f88511"
                            prepend-icon="mdi-camera"
                            show-size
                            dense
                          ></v-file-input>
                        </div>
                        <div>
                          <v-btn
                            class="mb-2 white--text"
                            :disabled="imgActividades==null || imgActividades == '' "
                            @click="addImgAct()"
                            color="primary"
                          >Subir</v-btn>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-slot:item.url="{value}">
                    <v-img aspect-ratio="1.7" height="12vh" width="9vw" :src="value"></v-img>
                  </template>
                  <template v-slot:item.acciones="{ item }">
                    <!-- <v-icon class="mr-2" color="#212121" @click="editItem(item)">mdi-pencil</v-icon> -->
                    <v-icon class="mr-2" color="red" @click="deleteImgAc(item)">mdi-delete</v-icon>
                    <a :href="item.url" target="_blank">
                      <v-icon class="mr-2" >{{mdiOpenInNew}}</v-icon>
                    </a>
                  </template>
                </v-data-table>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </tour-tabs>
  </div>
</template>
<script>
  import Tour from "@/models/Tour.js";
  import TourTabs from "@/components/admin/TourTabs";
  import Image from "@/models/ImageTour";
  import firebase from 'firebase/app';
  import 'firebase/firestore';
  import {
  mdiOpenInNew,
} from "@mdi/js";
  export default {
    name: "TourImages",
    components: { TourTabs },
    data() {
      return {
        tourModel:new Tour,
        ImageModel: new Image(),
        mdiOpenInNew: mdiOpenInNew,
        ///////////////////////////////////////////////
        tour:null,
        imgInicial: [],
        imagenA: [],
        imgActividades: [],
        imagenP: [],
        selectedA: [],
        snackbar:{
          show:false,
          color:'',
          message:''
        },
        fecha: firebase.firestore.Timestamp.fromDate(new Date),
        tableHeadersA: [
          {
            text: "Imagen",
            align: "center",
            sortable: false,
            value: "url",
          },
          // {
          //   text: "Descripcion",
          //   align: "start",
          //   sortable: false,
          //   value: "descripcion",
          // },
          {
            text: "Acciones",
            align: "start",
            sortable: false,
            value: "acciones",
          },
        ],
      };
    },
    methods: {
      addImg() {
        let loading = this.$loading.show();
        this.ImageModel.addImg(this.tourid, this.imgInicial)
          .then(() => {
            this.ImageModel.downImg(this.tourid, this.imgInicial.name).then(
              (url) => {
                this.urlimg = url;
                // console.log("Esta es la url" + this.urlimg);
                this.ImageModel.addImgDesc(
                  this.tourid,
                  this.urlimg,
                  this.imgInicial.name,
                  this.fecha
                ).then(() => {
                  this.imgInicial = [];
                  loading.hide();
                  this.snackbar.show = true;
                  this.snackbar.color = "green";
                  this.snackbar.message = `La imagen representativa del tour se agrego correctamente `;
                });
              }
            );
          })
          .catch((error) => {
            console.log(error.message);
            this.snackbar.show = true;
            this.snackbar.color = "red";
            this.snackbar.message =
              "Error la imagen representativa del tour no se agrego, por favor contacte al Administrador si el problema persiste";
          });
      },
      addImgAct() {
        let loading = this.$loading.show();
        this.ImageModel.addImgA(this.tourid, this.imgActividades).then(() => 
        {
          this.ImageModel.downImgA(this.tourid, this.imgActividades.name).then((urlact) => 
          {
            this.urlImgAct = urlact;
            this.ImageModel.foundName(this.tourid, this.imgActividades.name).get().then((q)=>{
              let conc = q;
              // console.log(conc);
              if(conc.empty){
                this.ImageModel.addImgDescAct(this.tourid, this.urlImgAct, this.imgActividades.name, this.fecha).then(() => {
                  this.imgActividades = [];
                  loading.hide();
                  this.snackbar.show = true;
                  this.snackbar.color = "green";
                  this.snackbar.message = `La imagen de actividades del tour se agrego correctamente `;
                });
              } else {
                console.log('data');
                this.imgActividades = [];
                loading.hide();
                this.snackbar.show = true;
                this.snackbar.color = "red";
                this.snackbar.message =
                  "Error, la imagen de actividades ya existe, por favor agrega una imagen distinta";
              }
            })
          });
        })
        .catch((error) => {
          console.log(error.message);
          this.snackbar.show = true;
          this.snackbar.color = "red";
          this.snackbar.message =
            "Error la imagen representativa del tour no se agrego, por favor contacte al Administrador si el problema persiste";
        });
      },
      deleteImg(name) {
        // console.log(name);
        this.$dialog
          .confirm(`¿Desea eliminar la imagen?`, {
            loader1: true,
          })
          .then((dialog) => {
            this.ImageModel.deleteImg(this.tourid)
              .then(() => {
                this.ImageModel.deleteStorageP(this.tourid, name).then(() => {
                  dialog.close();
                  this.showSnackbarSuccess(`El tour se elimino correctamente`);
                });
              })
              .catch((error) => {
                console.error("Error removing document: ", error);
                dialog.close();
                this.showSnackbarError(`Error al eliminar el tour, por favor contacte al Administrador si el problema persiste`);
              });
          })
          .catch(() => {
            console.log("Delete aborted");
          });
      },
      deleteImgAc(item) {
        //this.dialog.show = true;
        this.$dialog
          .confirm(`¿Desea eliminar la imagen?`, {
            loader1: true,
          })
          .then((dialog) => {
            this.ImageModel.deleteImgAct(this.tourid, item.id)
              .then(() => {
                this.ImageModel.deleteStorage(this.tourid, item.name).then(() => {
                  // dialog.close();
                  this.dialog.show = false;
                  this.snackbar.show = true;
                  this.snackbar.color = "green";
                  this.snackbar.message = `La imagen se elimino correctamente`;
                });
              })
              .catch((error) => {
                console.error("Error removing document: ", error);
                dialog.close();
                this.snackbar.show = true;
                this.snackbar.color = "red";
                this.snackbar.message = `Error al eliminar la imagen, por favor contacte al Administrador si el problema persiste`;
              });
          })
          .catch((error) => {
            console.log(error);
            console.log("Delete aborted");
          });
      },
      showSnackbarSuccess(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'green';
      },
      showSnackbarError(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'red';
      },
    },
    mounted() {
      this.tourid = this.$route.params.Id;
      this.tourModel.findByTour(this.$route.params.Id).onSnapshot((doc) => {
        this.tour = doc.data();
      });
      this.ImageModel.findImage(this.$route.params.Id).onSnapshot((qSnap) => {
        this.imagenP = [];
        let imagenes = qSnap.data();
        this.imagenP = imagenes;
        // console.log(this.imagenP);
      });
      this.ImageModel.findImageAct(this.$route.params.Id).onSnapshot((qsnap) => {
        this.imagenA = [];
        qsnap.forEach((doc) => {
          let imagenesA = doc.data();
          imagenesA.id = doc.id;
          this.imagenA.push(imagenesA);
        });
      });
    },
    created() {
      this.$store.commit("SET_LAYOUT", "admin");
    },
  };
</script>
<style>
  .file-input-width{
    flex-basis: calc(100% - 100px);
  }
</style>